import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form/immutable';
import { invoiceRequestsSelector } from '../selectors';
import { setUIStateAction } from '../../core/actions';
import { UPLOAD_FILE_MODAL } from '../constants';
import { downloadPoDocumentAction, submitInvoiceUploadFormAction } from '../actions';
import NafUploadFileModal from '../../widgets/UploadFileWidget/NafUploadFileModal';
import validations from '../../core/utils/validations';
import Spinner from '../../widgets/Spinner';
import { INVOICES } from '../../core/constants';
import RenderInvoiceRequest from './RenderInvoiceRequest';

const mapStateToProps = state => ({
  invoices: invoiceRequestsSelector(state) && invoiceRequestsSelector(state).toJS(),
});

const mapDispatchToProps = dispatch => ({
  openUploadFileModal: (invoiceRequestId) => {
    dispatch(setUIStateAction(UPLOAD_FILE_MODAL, true));
    dispatch(change('upload-file', 'invoiceRequestId', invoiceRequestId));
  },
  handleDownload: (fileId, fileName, contentType) => dispatch(downloadPoDocumentAction(fileId, fileName, contentType))
});


const ModalExtraField = props => (
  <div className={props.className}>
    <label htmlFor="add-invoice-pm">{props.label}</label>
    <input type="text" className={`form-control ${props.meta.touched && props.meta.error && 'is-invalid'}`} id="edit-exp-ref-name" {...props.input} />
    <div className="invalid-feedback">{props.meta.touched && props.meta.error}</div>
  </div>
);

ModalExtraField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.shape(),
  input: PropTypes.shape()
};

// eslint-disable-next-line no-unused-vars
const ExtraFields = () => (
  <div className="form-row modal-extra">
    <Field
      className="form-group col-md-8"
      label="Project Manager Email"
      name="ownerEmail"
      component={ModalExtraField}
      type="text"
      placeholder="someone@needafixer.com"
      validate={[validations.required, validations.email]}
    />
    <Field
      className="form-group col-md-4"
      label="Job Number"
      name="jobNumber"
      component={ModalExtraField}
      type="text"
      placeholder="22-9999"
      validate={[validations.required, validations.jobNumberPattern]}
    />
  </div>
);

const Invoices = ({ invoices, openUploadFileModal, handleDownload }) => {
  // Sort invoices by createdAt property in descending order
  const sortedInvoices = [...invoices].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <div className="row">
      <Spinner element={INVOICES} />
      <div className="card naf-card col-md-6">
        <div className="card-body">
          <h4 className="card-title"><span>INVOICES</span></h4>
          <ul className="naf-list-editing">
            {sortedInvoices.map(invoice => (
              <RenderInvoiceRequest key={invoice.id} {...invoice} openUploadFileModal={openUploadFileModal} handleDownload={handleDownload} />
            ))}
          </ul>
        </div>
        <NafUploadFileModal
          action={submitInvoiceUploadFormAction}
          stateIdentifier={UPLOAD_FILE_MODAL}
          title="PLEASE UPLOAD YOUR FILE"
        />
      </div>
    </div>
  );
};

Invoices.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape),
  openUploadFileModal: PropTypes.func,
  handleDownload: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
