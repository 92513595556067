import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import InvoiceDocuments from './InvoiceDocuments';

const statusClass = invoiceRequestStatus => `status-${invoiceRequestStatus.replace('_', '-').toLowerCase()}`;

const invoiceRequestStatusMap = {
  REJECT: 'REJECTED',
  ACCEPTED: 'PROCESSED',
  PENDING_APPROVAL: 'PENDING PROCESSING',
  PENDING_UPLOAD: 'PENDING INVOICE UPLOAD'
};

const DownloadButton = ({
  invoiceRequestId, jobNumber, poId, handleDownload
}) => (
  <button
    type="button"
    className="btn btn-link naf-save-btn btn-sm pl-1"
    onClick={() => handleDownload(invoiceRequestId, `PO-${poId}_${jobNumber}_invoice.pdf`, 'APPLICATION/PDF')}
  >
    <span className="naf-icon-download" />
  </button>
);

DownloadButton.propTypes = {
  invoiceRequestId: PropTypes.string.isRequired,
  jobNumber: PropTypes.string.isRequired,
  handleDownload: PropTypes.func.isRequired,
  poId: PropTypes.string.isRequired
};


const AddNewButton = ({ invoiceRequestId, openUploadFileModal }) => (
  <button
    type="button"
    className="btn btn-link naf-edit-btn"
    onClick={() => openUploadFileModal(invoiceRequestId)}
  >
    <span className="fa fa-plus" /> UPLOAD NEW INVOICE
  </button>
);

AddNewButton.propTypes = {
  invoiceRequestId: PropTypes.string.isRequired,
  openUploadFileModal: PropTypes.func.isRequired
};

const RenderInvoiceRequest = ({
  invoiceDocument, invoiceId, jobNumber, openUploadFileModal, invoiceRequestStatus, amount, poId, invoiceRequestId, rejectReason, handleDownload, name, createdAt
}) => {
  // Format createdAt date using moment.js
  const formattedCreatedAt = moment(createdAt).format('MMMM Do YYYY, h:mm:ss a');

  return (
    <li className="margin-bottom-li">
      <span>
        <div><strong>Invoice - { name }</strong></div>
        <div>Created At: {formattedCreatedAt}</div> {/* Display formatted date */}
        Download purchase order document
        <DownloadButton invoiceRequestId={invoiceRequestId} jobNumber={jobNumber} handleDownload={handleDownload} poId={poId} />
        <div>Job Number: <strong>{jobNumber}</strong></div>
        <div>Invoice Request Status: <span className={statusClass(invoiceRequestStatus)}>{invoiceRequestStatusMap[invoiceRequestStatus]}</span></div>
        {
          invoiceRequestStatus === 'REJECT' &&
          <div>
            {`Reject Reason: ${rejectReason}`}
          </div>
        }
        <div>Purchase Order ID: {poId}</div>
        <div>Amount: <strong>{amount && amount.amount} {amount && amount.currency}</strong> </div>
        {invoiceDocument && Object.entries(invoiceDocument).length ? (
          <InvoiceDocuments
            invoiceDocument={invoiceDocument}
            invoiceId={invoiceId}
            openUploadFileModal={() => openUploadFileModal(invoiceRequestId)}
          />) : <AddNewButton invoiceRequestId={invoiceRequestId} openUploadFileModal={openUploadFileModal} />
        }
      </span>
    </li>
  );
};

RenderInvoiceRequest.propTypes = {
  invoiceDocument: PropTypes.shape({
    contentType: PropTypes.string,
    fileId: PropTypes.string,
    filename: PropTypes.string,
    ownerUuid: PropTypes.string
  }),
  createdAt: PropTypes.string,
  invoiceId: PropTypes.string,
  jobNumber: PropTypes.string,
  openUploadFileModal: PropTypes.func,
  invoiceRequestStatus: PropTypes.string,
  poId: PropTypes.string,
  amount: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string
  }),
  invoiceRequestId: PropTypes.string,
  rejectReason: PropTypes.string,
  handleDownload: PropTypes.func,
  name: PropTypes.string
};

export default RenderInvoiceRequest;
